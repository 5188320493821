.schedule {
    list-style-type: none; /* Remove default list styling */
    padding: 20px; /* Add padding inside the card */
    margin: 20px 0; /* Add some margin around the card */
    background-color: #f9f9f9; /* Light background color for the card */
    border-radius: 10px; /* Rounded corners for the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a card-like appearance */
    border: solid 3px #007bff;
    li {
      padding: 10px 0; /* Add some padding around each list item */
      border-bottom: 1px solid #ddd; /* Optional: Add a subtle divider between list items */
      position: relative; /* Ensure the badge can be positioned relative to each list item */
      &:last-child {
        border-bottom: none; /* Remove the border from the last list item */
      }
      &:after {
        content: ""; /* Clear the float for each list item */
        display: block;
        clear: both;
      }
    }
  }
  
  .badge {
    float: right; /* Float the badge to the right of the list item */
    background-color: #007bff; /* Background color for the badge */
    color: #fff; /* Text color for the badge */
    padding: 3px 12px; /* Padding inside the badge */
    border-radius: 20px; /* Rounded shape for the badge */
    font-size: 0.9em; /* Slightly smaller text size */
    font-weight: bold; /* Bold text for the badge */
    margin-left: 10px; /* Add space between the badge and the text */
    display: inline-block; /* Ensure proper sizing of the badge */
  }