/* Container for the entire tabbed card */
.tabbed {
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    overflow: hidden;
}

/* Style for the tab buttons */
.tabs {
    display: flex;
    justify-content: flex-start;
    background-color: #333;
}

.tabs .tablink {
    flex: 1;
    padding: 10px 20px;
    background-color: #333;
    color: white;
    text-align: center;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.tabs .tablink:hover {
    background-color: #555;
}

.tabs .tablink.active {
    background-color: #2196F3;
}

/* Tab content */
.tab-content {
    display: none;
    padding: 20px;
    background-color: white;
}

.tab-content.active {
    display: block;
}