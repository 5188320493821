.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.pagination a,
.pagination span {
    color: #0366d6;
    text-decoration: none;
    padding: 8px 16px;
    margin: 0 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.pagination a:hover {
    background-color: #0366d6;
    color: #fff;
    border-color: #0366d6;
}

.pagination .active {
    background-color: #0366d6;
    color: #fff;
    border-color: #0366d6;
    cursor: default;
}
