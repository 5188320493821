$vertical-video-max-height: 250px;


body {
  // padding-top: 70px;
  // background: #FFF url(/assets/img/bg.png) repeat 0 0;

  .wrapper {
    // background-color: white;
  }
}

h3.acharya {
  text-align: center;
}

div.video-box {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (9/16 * 100 = 56.25%) */
  height: 0;
  overflow: hidden;
  margin-bottom: 1em;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  &.vertical {
    /* Set height based on viewport height minus space for other elements */
    padding-bottom: 177.78%; /* 9:16 aspect ratio (16/9 * 100 = 177.78%) */
    height: auto;
    max-height: calc(100vh - #{$vertical-video-max-height}); /* Adjust 150px as needed for top navigation, title, etc. */
    max-width: calc((100vh - #{$vertical-video-max-height}) * 9 / 16); /* Maintain aspect ratio within screen height */
    margin-left: auto;
    margin-right: auto;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  flex-wrap: nowrap;
  margin-top: 20px;
  
  > .stretch {
    flex: 1;
    margin: 0;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    flex-wrap: wrap;

    > .stretch {
      flex: none;
      width: 100%; /* Ensure each item takes full width in column layout */
    }
  }
}

.post-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: nowrap;
  // margin-top: 20px;

  > .thumb {
    width: 80px;
    margin: 0;
  }
  
  > .content {
    flex: 1;
    margin: 0;
    color: #666;
    h3 {
      margin-bottom: 3px;
      line-height: 1.5em;
    }
    .post-summary {
      // font-size: $base-font-size * 1.1;
      color: $grey-color;
    }
  }
}

.post-img {
  margin-bottom: 20px;
  text-align: center;
  img {
    max-width: 100%;
  }
}

.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.footer-name {
  color: #333;
  font-weight: 600;
}

.schedule {
  list-style-type: none; /* Remove default list styling */
  padding: 20px; /* Add padding inside the card */
  margin: 20px 0; /* Add some margin around the card */
  background-color: #f9f9f9; /* Light background color for the card */
  border-radius: 10px; /* Rounded corners for the card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a card-like appearance */
  border: solid 3px #007bff;
  li {
    padding: 10px 0; /* Add some padding around each list item */
    border-bottom: 1px solid #ddd; /* Optional: Add a subtle divider between list items */
    position: relative; /* Ensure the badge can be positioned relative to each list item */
    &:last-child {
      border-bottom: none; /* Remove the border from the last list item */
    }
    &:after {
      content: ""; /* Clear the float for each list item */
      display: block;
      clear: both;
    }
  }
}

.badge {
  float: right; /* Float the badge to the right of the list item */
  background-color: #007bff; /* Background color for the badge */
  color: #fff; /* Text color for the badge */
  padding: 3px 12px; /* Padding inside the badge */
  border-radius: 20px; /* Rounded shape for the badge */
  font-size: 0.9em; /* Slightly smaller text size */
  font-weight: bold; /* Bold text for the badge */
  margin-left: 10px; /* Add space between the badge and the text */
  display: inline-block; /* Ensure proper sizing of the badge */
}
